var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-container',{staticClass:"d-flex justify-center align-center fill-height"},[_c('v-sheet',{staticClass:"py-10 px-6",attrs:{"elevation":"2","width":"100%","max-width":"25rem","rounded":""}},[_c('h1',{staticClass:"mb-6 text-h5 text-center"},[_vm._v("Bahandi.Salary")]),_c('v-form',{ref:"form",on:{"submit":function($event){$event.preventDefault();return _vm.tryLogin.apply(null, arguments)}}},[_c('v-text-field',{staticClass:"rounded-sm mb-4",attrs:{"label":"ИИН","color":"primary","rules":[
          (v) => !!v || 'Введите ИИН',
          (v) => Number.isInteger(Number(+v)) || 'Введите ИИН',
          (v) => v.length === 12 || 'Введите 12 цифр',
        ],"outlined":"","hide-details":"","validate-on-blur":""},model:{value:(_vm.taxpayerIdNumber),callback:function ($$v) {_vm.taxpayerIdNumber=$$v},expression:"taxpayerIdNumber"}}),_c('v-text-field',{staticClass:"rounded-sm mb-4",attrs:{"label":"Номер карты","color":"primary","rules":[
          (v) => !!v || 'Введите номер карты',
          (v) => Number.isInteger(Number(+v)) || 'Введите номер карты',
        ],"outlined":"","hide-details":"","validate-on-blur":""},model:{value:(_vm.cardNumber),callback:function ($$v) {_vm.cardNumber=$$v},expression:"cardNumber"}}),_c('v-btn',{attrs:{"type":"submit","color":"primary","loading":_vm.isLoading,"block":""}},[_vm._v("Войти")])],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }