import axios from "axios";

const api = axios.create({
  baseURL: process.env.VUE_APP_API_URL,
  withCredentials: true,
});

export const fetchToken = (taxpayerIdNumber, cardNumber) => {
  return api.post("/auth/token", {
    taxpayerIdNumber,
    cardNumber,
  });
};

export const deleteToken = () => {
  return api.delete("/auth/token");
};

export const fetchReport = (month, year) => {
  return api.get(`/employees/report?month=${month}&year=${year}`);
};

export const fetchPenalties = (month, year) => {
  return api.get(`/employees/penalties?month=${month}&year=${year}`);
};

export const fetchBonuses = (month, year) => {
  return api.get(`/employees/bonuses?month=${month}&year=${year}`);
};

export const fetchPayouts = (month, year) => {
  return api.get(`/employees/payouts?month=${month}&year=${year}`);
};

export const fetchTransactions = ({ month, year, type }) => {
  switch (type) {
    case "penalties":
      return fetchPenalties(month, year);
    case "bonuses":
      return fetchBonuses(month, year);
    case "payouts":
      return fetchPayouts(month, year);
  }
};
