<template>
  <v-app>
    <v-app-bar color="primary" dark app>
      <v-toolbar-title>Bahandi.Salary</v-toolbar-title>

      <v-spacer></v-spacer>

      <v-btn text @click="logout">Выйти</v-btn>
    </v-app-bar>

    <v-main>
      <router-view />
    </v-main>

    <v-bottom-navigation color="primary" app>
      <v-btn v-for="link in links" :key="link.text" :to="link.to">
        <span>{{ link.text }}</span>

        <v-icon>{{ link.icon }}</v-icon>
      </v-btn>
    </v-bottom-navigation>
  </v-app>
</template>

<script>
import { deleteToken } from "@/api/api";
export default {
  name: "App",

  data: () => ({
    links: [
      {
        icon: "mdi-account-outline",
        text: "Профиль",
        to: "/profile",
      },
      {
        icon: "mdi-receipt-text-outline",
        text: "Начисления",
        to: "/bonuses",
      },
      {
        icon: "mdi-alert-circle-outline",
        text: "Удержания",
        to: "/penalties",
      },
      {
        icon: "mdi-cash-multiple",
        text: "Выплаты",
        to: "/payouts",
      },
    ],
  }),

  methods: {
    async logout() {
      deleteToken();
      this.deleteCookie("isAuth");
      this.$router.push("/login");
    },

    deleteCookie(name) {
      document.cookie =
        name + "=; Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT;";
    },
  },
};
</script>
