import LoginView from "@/views/LoginView.vue";
import Vue from "vue";
import VueRouter from "vue-router";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    redirect: "/profile",
  },
  {
    path: "/login",
    name: "login",
    component: LoginView,
    meta: {
      layout: "auth",
      isPublic: true,
    },
  },
  {
    path: "/profile",
    name: "profile",
    component: () =>
      import(/* webpackChunkName: "profile" */ "@/views/ProfileView.vue"),
  },
  {
    path: "/penalties",
    name: "penalties",
    component: () =>
      import(/* webpackChunkName: "penalties" */ "@/views/PenaltiesView.vue"),
  },
  {
    path: "/bonuses",
    name: "bonuses",
    component: () =>
      import(/* webpackChunkName: "bonuses" */ "@/views/BonusesView.vue"),
  },
  {
    path: "/payouts",
    name: "payouts",
    component: () =>
      import(/* webpackChunkName: "payouts" */ "@/views/PayoutsView.vue"),
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

router.beforeEach((to, _, next) => {
  const isPublic = to.matched.some((record) => record.meta.isPublic);

  const isAuthCookie = document.cookie
    .split("; ")
    .find((row) => row.startsWith("isAuth="))
    ?.split("=")[1];

  if (!isPublic && !isAuthCookie) {
    return next({ name: "login" });
  }

  if (to.name === "login" && isAuthCookie) {
    return next({ name: "profile" });
  }

  return next();
});

export default router;
