<template>
  <v-container class="d-flex justify-center align-center fill-height">
    <v-sheet
      elevation="2"
      class="py-10 px-6"
      width="100%"
      max-width="25rem"
      rounded
    >
      <h1 class="mb-6 text-h5 text-center">Bahandi.Salary</h1>

      <v-form @submit.prevent="tryLogin" ref="form">
        <v-text-field
          label="ИИН"
          class="rounded-sm mb-4"
          color="primary"
          v-model="taxpayerIdNumber"
          :rules="[
            (v) => !!v || 'Введите ИИН',
            (v) => Number.isInteger(Number(+v)) || 'Введите ИИН',
            (v) => v.length === 12 || 'Введите 12 цифр',
          ]"
          outlined
          hide-details
          validate-on-blur
        ></v-text-field>

        <v-text-field
          label="Номер карты"
          class="rounded-sm mb-4"
          color="primary"
          v-model="cardNumber"
          :rules="[
            (v) => !!v || 'Введите номер карты',
            (v) => Number.isInteger(Number(+v)) || 'Введите номер карты',
          ]"
          outlined
          hide-details
          validate-on-blur
        ></v-text-field>

        <v-btn type="submit" color="primary" :loading="isLoading" block
          >Войти</v-btn
        >
      </v-form>
    </v-sheet>
  </v-container>
</template>

<script>
import { fetchToken } from "@/api/api";
import { AxiosError } from "axios";

export default {
  name: "LoginView",

  data: () => ({
    taxpayerIdNumber: "",
    cardNumber: "",
    isLoading: false,
  }),

  methods: {
    async tryLogin() {
      if (!this.$refs.form.validate()) return;

      this.isLoading = true;

      try {
        await fetchToken(this.taxpayerIdNumber, this.cardNumber);

        this.$router.push({ name: "profile" });
      } catch (e) {
        if (e instanceof AxiosError) {
          if (e.response?.status === 401) {
            this.$toast.error("Неверный ИИН или номер карты!");
          } else {
            this.$toast.error("Произошла неизвестная ошибка!");
          }
        }
      } finally {
        this.isLoading = false;
      }
    },
  },
};
</script>
