<template>
  <component :is="currentLayoutComponent"></component>
</template>

<script>
import AuthLayout from "./layout/AuthLayout.vue";
import StandardLayout from "./layout/StandardLayout.vue";

const layoutComponents = {
  auth: AuthLayout,
  standard: StandardLayout,
};

export default {
  computed: {
    currentLayoutComponent() {
      if (!this.$route.matched.length) return null;

      const layout = this.$route.meta?.layout || "standard";

      return layoutComponents[layout];
    },
  },
};
</script>
